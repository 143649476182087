<template>
  <ValidationObserver v-slot="{ passes }">
    <v-form @submit.prevent="passes(questionUpdate)">
      <v-card>
        <v-card-title>
          Question update
        </v-card-title>
        <v-card-text>
          <editor
            v-model="question.title"
          />
          <img
            v-if="question.new_image"
            :src="$displayImage(question.new_image)"
            style="width: 300px"
          >
          <img
            v-if="question.image && !question.new_image"
            :src="$getFullPath(question.image)"
            style="width: 300px"
          >
          <v-file-input
            v-model="question.new_image"
            accept="image/*"
            label="Image"
          />
          <ValidationProvider
            v-slot="{ errors }"
            name="quiz"
            rules="required"
          >
            <v-autocomplete
              v-model="question.quiz_id"
              :items="quizes"
              item-value="id"
              item-text="title"
              :error-messages="errors"
              label="Quiz"
            />
          </ValidationProvider>
          <BtnToggle
            v-model="question.status"
            :positive="{text: 'Active'}"
            :negative="{text: 'Inactive'}"
          />
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-spacer />
          <v-btn
            type="submit"
            color="primary"
            :loading="loading"
          >
            {{ $t('action.update') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import authHeader from '@/utils'
import editor from '@/components/Editor'
import BtnToggle from '@/components/BtnToggle'

export default {
  components: { BtnToggle, editor },
  breadcrumb () {
    return {
      label: this.$t('action.edit') + ' ' + this.question.title
    }
  },
  data () {
    return {
      loading: false,
      question: {
        status: true
      },
      quizes: []
    }
  },
  beforeMount () {
    this.getQuizes()
    this.getQuestionsByID()
  },
  methods: {
    getQuestionsByID () {
      this.$http
        .get('/questions/?id[]=' + this.$route.params.id, { headers: authHeader() })
        .catch(error => this.displayError(error))
        .then(({ data: { data } }) => {
          if (!data[0]) {
            this.$router.push('/404')
          } else {
            this.question = data[0]
          }
        })
    },
    questionUpdate () {
      const formData = new FormData()
      if (this.question.new_image) {
        formData.append('image', this.question.new_image)
      }
      formData.append('id', this.question.id)
      formData.append('quiz_id', this.question.quiz_id)
      formData.append('title', this.question.title)
      formData.append('status', this.question.status ? 1 : 0)
      this.loading = true
      this.$http
        .post('/questions/' + this.$route.params.id, formData, { headers: authHeader() })
        .then(() => {
          this.$store.commit('setNotification', { color: 'success', message: this.$t('action.update_success') })
          this.$router.push({ name: 'dashboard.question' })
        })
        .catch(error => {
          this.loading = false
          this.displayError(error)
        })
    },
    getQuizes () {
      this.$http
        .get('/quizes/?take=500', { headers: authHeader() })
        .catch(error => {
          this.displayError(error)
        })
        .then(({ data }) => {
          this.quizes = data.data
        })
    }
  }
}
</script>
